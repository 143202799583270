<template>
<div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

        <!-- Brand logo
      <b-link class="brand-logo">
        <vuexy-logo />

        <h2 class="brand-text text-primary ml-1">
          Vuexy
        </h2>
      </b-link>-->
        <!-- /Brand logo-->

        <!-- Left Text-->
        <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
            <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                <b-img fluid :src="imgUrl" alt="Forgot password V2" />
            </div>
        </b-col>
        <!-- /Left Text-->

        <!-- Forgot password-->
        <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
            <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
                <b-card-text class="text-center mt-2">
                    <b-link>
                        <b-img :src="resolveLogo()" alt="Logo" style="width: 55%; margin-bottom: 1.5rem;" />
                    </b-link>
                </b-card-text>
                <b-card-title class="mb-1">
                    Acess Key 🔑
                </b-card-title>
                <b-card-text class="mb-2">
                    Enter your email and we will send your access key
                </b-card-text>

                <!-- form -->
                <validation-observer ref="simpleRules">
                    <b-form class="auth-forgot-password-form mt-2" @submit.prevent="validationForm">
                        <b-form-group label="Email" label-for="forgot-password-email">
                            <validation-provider #default="{ errors }" name="Email" rules="required|email">
                                <b-form-input id="forgot-password-email" v-model="userEmail" :state="errors.length > 0 ? false:null" name="forgot-password-email" placeholder="myemail@example.com" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-button type="submit" variant="primary" block>
                            Send
                        </b-button>
                    </b-form>
                </validation-observer>

                <div class="divider my-2">
                    <div class="divider-text">
                        or
                    </div>
                </div>

                <b-card-text class="text-center mt-2">
                    <span>Do you already have an access key?  </span>
                    <b-link :to="{name:'auth-acess-key'}">
                        <span>Go to authentication page </span>
                    </b-link>
                </b-card-text>

                <p class="text-center mt-2">
                    <b-link :to="{name:'auth-login'}">
                        <feather-icon icon="ChevronLeftIcon" /> Back to login
                    </b-link>
                </p>

                
            </b-col>
        </b-col>
        <!-- /Forgot password-->
    </b-row>
</div>
</template>

<script>
/* eslint-disable global-require */
import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
    BRow,
    BCol,
    BLink,
    BCardTitle,
    BCardText,
    BImg,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
} from 'bootstrap-vue'
import {
    required,
    email
} from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from "axios";
import router from '@/router'

import customerSettigns from '@core/utils/customerSettigns'

import systemStoreModule from '@core/layouts/components/app-navbar/systemStoreModule'

import { onUnmounted} from '@vue/composition-api'

export default {
    components: {
        VuexyLogo,
        BRow,
        BCol,
        BLink,
        BImg,
        BForm,
        BButton,
        BFormGroup,
        BFormInput,
        BCardTitle,
        BCardText,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            userEmail: '',
            sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
            // validation
            required,
            email,

            userSettings: null,
            loadingLogo: true
        }
    },
    computed: {
        imgUrl() {
            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
                return this.sideImg
            }
            return this.sideImg
        },
    },
    methods: {


        async getSetings() {
            this.loadingLogo = true
            this.userSettings = await customerSettigns()
            this.loadingLogo = false
        },
        resolveLogo() {

            console.log(`userSettings`, this.userSettings)
            if (!this.userSettings) {

                return require('@/assets/images/logo/LogoExtend.png')
            } else {

                return this.userSettings.logoUrl
            }
        },


        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {

                    axios
                        .post("/JWT/RequestAccessKey", {
                            email: this.userEmail
                        })
                        .then((res) => {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Acess Key sent to your email.',
                                    icon: 'EditIcon',
                                    variant: 'success',
                                },
                            })
                            localStorage.userEmail = this.userEmail
                            router.push({ name: 'auth-acess-key'  })
                        })
                        .catch((error) => {
                            console.log(error);

                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Failed to send email.',
                                    icon: 'EditIcon',
                                    variant: 'error',
                                },
                            })
                        });

                }
            })
        },
    },
    mounted() { // mounted
        this.getSetings()
    },
    setup() {
        const SYSTEM_APP_STORE_MODULE_NAME = 'app-system'

        // Register module
        if (!store.hasModule(SYSTEM_APP_STORE_MODULE_NAME)) store.registerModule(SYSTEM_APP_STORE_MODULE_NAME, systemStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(SYSTEM_APP_STORE_MODULE_NAME)) store.unregisterModule(SYSTEM_APP_STORE_MODULE_NAME)
        })
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
